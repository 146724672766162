




























































































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { organisationModule } from "@/store/modules/OrganisationModule";
import { OrganisationDetails } from "@/models/OrganisationDetails";
import { OrganisationUnit } from "../models/OrganisationUnit";
import { BvModalEvent } from "bootstrap-vue";
import router from "@/router";

@Component
export default class OrganisationUnitComponent extends Vue {
  public organisationUnit?: OrganisationUnit = null;
  public allOrganisationUnits?: OrganisationUnit[] = null;
  public addCostCenterCode = "";

  public get organisation(): OrganisationDetails {
    return organisationModule.organisationDetails;
  }

  public showAddCostCenter() {
    this.$bvModal.show("add-cost-center");
  }

  public addCostCenter(e: BvModalEvent) {
    try {
      const codeParsed = parseInt(this.addCostCenterCode);

      // if (this.organisationUnit.costCenters.find(x => x.toString() === codeParsed.toString())) {
      //   e.preventDefault();
      // }
      // else {
      if (!isNaN(codeParsed)) {
        this.organisationUnit.costCenters.push(codeParsed);
      }
      // }
    }
    catch(e) {}

    this.addCostCenterCode = "";

    // this.$bvModal.hide("add-cost-center");
  }

  public deleteCostCenter(costCenter: number) {
    this.organisationUnit.costCenters = this.organisationUnit.costCenters.filter(x => x.toString() !== costCenter.toString())
  }

  public async save() {
    if (this.organisationUnit.id) {
      await adminService.updateOrganisationUnit(this.organisation.id, this.organisationUnit);
    }
    else {
      this.organisationUnit.id = await adminService.addOrganisationUnit(this.organisation.id, this.organisationUnit);

      router.replace("/organisation/units/edit/" + this.organisationUnit.id);
    }
  }

  public deleteUnit() {
    this.$bvModal.msgBoxConfirm("Är du säker på att du vill radera denna enhet?").then(confirm => {
      if (confirm) {
        adminService.deleteOrganisationUnit(this.organisationUnit.id).then(v => {
          router.replace("/organisation/units/");
        });
      }
    });
  }

  private async activated() {
    this.allOrganisationUnits = await adminService.getOrganisationUnits(this.organisation.id);

    if (this.$route.params.id) {
      this.organisationUnit = await adminService.getOrganisationUnit(this.$route.params.id);
    }
    else {
      this.organisationUnit = {
        id: undefined,
        name: "",
        costCenters: [],
        parentId: this.$route.params.parentId ?? undefined,
      } as OrganisationUnit;
    }
  }
}
